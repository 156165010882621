if ('serviceWorker' in navigator) {
	navigator.serviceWorker.register(
	   new URL("./serviceWorker.js", import.meta.url),
	   {type: 'module', scope: '/'}
	);
}

var canvas = document.createElement('canvas');
canvas.width = document.documentElement.clientWidth;
canvas.height = document.documentElement.clientHeight;
document.body.appendChild(canvas);

var context = canvas.getContext('2d');

var padding = 10;
var tickPadding = 10;
var numTicks = 20;
var tickLength = 0.2; // as percent of radius
var pinRadius = 16;

var _radius = (canvas.width - 2 * padding) / 2;
var radius = _radius;
var  center = {
    x: Math.min(canvas.width / 2, canvas.width - padding - radius),
    y: canvas.height / 2 };
var angle = 0;
var section_angle = 0;
var pins = [];
var last_drop_angle = null;

const rgba2hex = (rgba) => `#${rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+\.{0,1}\d*))?\)$/).slice(1).map((n, i) => (i === 3 ? Math.round(parseFloat(n) * 255) : parseFloat(n)).toString(16).padStart(2, '0').replace('NaN', '')).join('')}`;

document.addEventListener('DOMContentLoaded', function() {
    var playPauseButton = document.getElementById('play_button');
    var playImg = document.getElementById('play_img');
    var pauseImg = document.getElementById('pause_img');
    var zoomSlider = document.getElementById('zoom_slider');
    var sectionSlider = document.getElementById('rotate_slider');
    var speedSlider = document.getElementById('speed_slider');
    var fileBrowser = document.getElementById('browser');
    var audio = document.getElementsByTagName('audio')[0];
    var audioTimeSpan = document.getElementsByTagName('span')[0];
    var pinButton = document.getElementById('pin_button');
    var lastDropButton = document.getElementById('last_drop_button');
    var pinTextField = document.getElementById('pin_text');
    var pinColourField = document.getElementById('pin_colour');
    var pinSizeSlider = document.getElementById('pin_size_slider');
    
    var current_pin = null;

    class Pin {
        constructor(timestamp) {
            this.timestamp = timestamp;

            this.angle = (this.timestamp / audio.duration) * (2*Math.PI);
            this.name = 'pin';
            this.note = '';
            this.colour = 'rgba(211,55,50,1)';

            this.element = document.createElement('button');
            this.element.style.position = 'absolute';
            this.element.style.width = (pinRadius * 2) + 'px';
            this.element.style.height = (pinRadius * 2) + 'px';
            this.element.style.borderRadius = '50%';
            this.element.style.top = ((center.y + (radius - pinRadius) * Math.sin(this.angle + section_angle)) - pinRadius) + 'px';
            this.element.style.left = ((center.x + (radius - pinRadius) * Math.cos(this.angle + section_angle)) - pinRadius) + 'px';
            this.element.style.background = '#ff0000';
            this.element.classList.add('pin');
            this.element.innerHTML = '<span></span>';
            var that = this;
            this.element.addEventListener('click', function(e) {
                document.getElementsByTagName('audio')[0].currentTime = timestamp;
                current_pin = that;
                pinTextField.value = that.element.children[0].innerHTML;
                pinColourField.value = rgba2hex(that.element.style.backgroundColor);
            });

            document.body.appendChild(this.element);
        }
    }

    pinTextField.addEventListener('input', function(e) {
        if (current_pin != null) {
            current_pin.element.innerHTML = '<span>' + e.target.value + '</span>';
        }
    });
    pinColourField.addEventListener('input', function(e) {
        if (current_pin != null) {
            current_pin.element.style.background = e.target.value;
        }
    });
    pinSizeSlider.addEventListener('input', function(e) {
        pinRadius = (e.target.value / 10.0) / 2.0;
        if (current_pin != null) {
            for (let i=0; i<pins.length; i++) {
                pins[i].element.style.top = (center.y + (radius - pinRadius) * Math.sin(pins[i].angle + section_angle) - pinRadius) + 'px';
                pins[i].element.style.left = (center.x + (radius - pinRadius) * Math.cos(pins[i].angle + section_angle) - pinRadius) + 'px';
                pins[i].element.style.width = (e.target.value / 10.0) + 'px';
                pins[i].element.style.height = (e.target.value / 10.0) + 'px';
            }
        }
    });

    function animationFrame(timestamp) {
        if (audio.paused) {
            return;
        }
        angle = (audio.currentTime / audio.duration) * (2 * Math.PI);
        audioTimeSpan.innerHTML = audio.currentTime.toFixed(2).padStart(7,' ');
        redraw();
        window.requestAnimationFrame(animationFrame);
    }

    audio.addEventListener('play', function(e) {
        playImg.style.display = 'none';
        pauseImg.style.display = 'block';
        window.requestAnimationFrame(animationFrame);
    });
    audio.addEventListener('pause', function(e) {
        playImg.style.display = 'block';
        pauseImg.style.display = 'none';
    });

    last_drop_button.addEventListener('click', function(e) {
        if (last_drop_angle != null) {
            audio.currentTime = (last_drop_angle / (2*Math.PI)) * audio.duration;
        }
    });

    pinButton.addEventListener('click', function(e) {
        let currentTime = (angle / (2*Math.PI)) * audio.duration;
        if (isNaN(currentTime)) {
            alert('Can\'t pin location, when there\'s no audio file.');
            return;
        }
        pins.push(new Pin(currentTime));
    })

    playPauseButton.addEventListener('click', function(e) {
        if (audio.paused) {
            play_audio();
        } else {
            audio.pause();
        }
    });

    fileBrowser.addEventListener('input', function(e) {
        audio.src = URL.createObjectURL(e.target.files[0]);
    });

    speedSlider.addEventListener('input', function(e) {
        audio.playbackRate = e.target.value / 1000.0;
    });

    zoomSlider.addEventListener('input', function(e) {
        radius = _radius * ((e.target.value) / 100.0);
        center = {
            x: Math.min(canvas.width / 2, canvas.width - padding - radius),
            y: canvas.height / 2 };
        redraw();

        for (let i=0; i<pins.length; i++) {
            pins[i].element.style.top = (center.y + (radius - pinRadius) * Math.sin(pins[i].angle + section_angle) - pinRadius) + 'px';
            pins[i].element.style.left = (center.x + (radius - pinRadius) * Math.cos(pins[i].angle + section_angle) - pinRadius) + 'px';
        }
    });

    sectionSlider.addEventListener('input', function(e) {
        section_angle = (e.target.value / 2000.0) * (2 * Math.PI);

        for (let i=0; i<pins.length; i++) {
            pins[i].element.style.top = (center.y + (radius - pinRadius) * Math.sin(pins[i].angle + section_angle) - pinRadius) + 'px';
            pins[i].element.style.left = (center.x + (radius - pinRadius) * Math.cos(pins[i].angle + section_angle) - pinRadius) + 'px';
        }
    });

    function play_audio() {
        if (audio.src == '') {
            if (fileBrowser.files[0] == undefined) {
                alert('No audio file selected')
                return;
            } else {
                audio.src = URL.createObjectURL(fileBrowser.files[0]);
            }
        }
        audio.play()
    }

    var is_mouse_down = false;
    var p_start = null;
    var t_start = null;
    function mousedown(e) {
        e.preventDefault();
        is_mouse_down = true;
        document.activeElement.blur();
        if (e.clientX == undefined) {
            p_start = {x: e.touches[0].clientX, y: e.touches[0].clientY};
        } else {
            p_start = {x: e.clientX, y: e.clientY};
        }
        t_start = audio.currentTime;
    }
    function mouseup(e) {
        e.preventDefault();
        play_audio()
        mousemove(e);
        is_mouse_down = false;
        p_start = null;
        last_drop_angle = (audio.currentTime / audio.duration) * (2*Math.PI);
        redraw();
    }
    function mousemove(e) {
        e.preventDefault();
        if (p_start == null) {
            is_mouse_down = false;
            return;
        }
        let p_this;
        if (e.clientX == undefined) {
            p_this = {x: e.touches[0].clientX, y: e.touches[0].clientY};
        } else {
            p_this = {x: e.clientX, y: e.clientY};
        }
        let v_start = {x: p_start.x - center.x, y: p_start.y - center.y};
        let len_v_start = Math.sqrt(v_start.x * v_start.x + v_start.y * v_start.y);
        let start_angle = Math.atan2(v_start.y, v_start.x);
        let v_this = {x: p_this.x - center.x, y: p_this.y - center.y};
        let len_v_this = Math.sqrt(v_this.x * v_this.x + v_this.y * v_this.y);
        let this_angle = Math.atan2(v_this.y, v_this.x);
        let _angle = (2*Math.PI + (this_angle - start_angle)) % (2 * Math.PI);
        let currentTime = (t_start + (_angle / (2 * Math.PI)) * audio.duration) % audio.duration;
        if (!isNaN(currentTime)) {
            if (audio.paused) {
                angle = (currentTime / audio.duration) * (2 * Math.PI);
                audioTimeSpan.innerHTML = currentTime;
            } else {
                audio.currentTime = currentTime;
            }
        }
        redraw();
    }
    canvas.addEventListener('mousedown', mousedown);
    canvas.addEventListener('mouseup', mouseup);
    canvas.addEventListener('mousemove', mousemove);
    canvas.addEventListener('touchstart', mousedown);
    canvas.addEventListener('touchend', mouseup);
    canvas.addEventListener('touchmove', mousemove);

    window.addEventListener('resize', function(e) {
        canvas.width = document.documentElement.clientWidth;
        canvas.height = document.documentElement.clientHeight;
        _radius = (canvas.width - 2 * padding) / 2;
        radius = _radius * ((zoomSlider.value) / 100.0);
        center = {
            x: Math.min(canvas.width / 2, canvas.width - padding - radius),
            y: canvas.height / 2 };
        redraw();

        for (let i=0; i<pins.length; i++) {
            pins[i].element.style.top = (center.y + (radius - pinRadius) * Math.sin(pins[i].angle + section_angle) - pinRadius) + 'px';
            pins[i].element.style.left = (center.x + (radius - pinRadius) * Math.cos(pins[i].angle + section_angle) - pinRadius) + 'px';
        }
    });
});

function drawBg() {
    context.save();

    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillStyle = 'rgba(50,50,50,1)';
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.restore();
}

function drawDial() {
    context.save()

    context.beginPath();
    context.arc(center.x,center.y,radius,0, 5 * Math.PI, false);
    context.closePath();

    context.lineWidth = 5;
    context.stroke();

    context.fillStyle = 'rgba(125,125,125,1)';
    context.fill();

    // Dial ticks
    context.strokeStyle = 'rgba(0,0,0,0.5)';
    context.lineWidth = 2;
    let step = (2 * Math.PI) / numTicks
    for (let i=0; i< numTicks; i++) {
        let start_d = radius * (1-tickLength);
        if (i == 0) {
            start_d = 0;
        }
        context.beginPath();
        context.moveTo(center.x + start_d * Math.cos(step * i + angle + section_angle),
                       center.y + start_d * Math.sin(step * i + angle + section_angle));
        context.lineTo(center.x + (radius - tickPadding) * Math.cos(step * i + angle + section_angle),
                       center.y + (radius - tickPadding) * Math.sin(step * i + angle + section_angle));
        context.stroke();
    }

    // Draw line at last drop angle
    if (last_drop_angle != null) {
        context.strokeStyle = 'rgba(220,220,30,0.3)';
        context.lineWidth = 6;
        context.beginPath();
        context.moveTo(center.x, center.y);
        context.lineTo(center.x + radius * Math.cos(last_drop_angle + section_angle),
                       center.y + radius * Math.sin(last_drop_angle + section_angle));
        context.stroke();
    }

    context.restore();
}

function redraw() {
    drawBg();
    drawDial();
}

redraw();
